<template>
    <div class="attshiftcont">
        <div style="display: flex;">
            <Headbuttom></Headbuttom>
            <Tabheader @handRegister="handRegister" @handFromshow="handFromshow" :titlename="titlename" :btnState='false' @handBatch='handBatch' :btnState2='false'></Tabheader>
        </div>
        

        <div class="attheadfrom" v-show="flag">
            <el-form :inline="true" size="small" :model="searchParams" class="demo-form-inline">
                <el-form-item :label="$t('devtable.llkxh')">
                    <el-input v-model="searchParams.name" :placeholder="$t('devtable.llkxh')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('devtable.sbh')">
                    <el-input v-model="searchParams.serial_number" :placeholder="$t('devtable.sbh')"></el-input>
                </el-form-item>                
                <el-form-item :label="$t('devtable.qsrq')">
                    <el-date-picker
                    v-model="searchParams.start_time"
                    type="date"
                    :placeholder="$t('devtable.xzrq')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('devtable.jsrq')">
                    <el-date-picker
                    v-model="searchParams.end_time"
                    type="date"
                    :placeholder="$t('devtable.xzrq')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">{{$t('devtable.cx')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
        <Tables :tableLabel="tableLabel" :tableData="tableData" :flags="flags" :configs="configs" :operation="operation" @handlerow='handlerow' @handlpages='handlpages'></Tables>
        <Attshitdilg ref="attshitdilg"></Attshitdilg>
		<!-- 错误弹窗 -->
		<div class="errPop" v-show="errPopState">
			<div class="close" @click="closePop">X</div>
			<div class="popTitle">同步失败</div>
			<div class="popNav">
				<div class="navIndex">设备号</div>
			</div>
			<div>
				<div class="scroll">
					<div v-for="(item,index) in errDataList" :key="index" class="itemBox">
						<div class="navIndex">{{item}}</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from '@/views/workattendance/components/tabheader'
import Attshitdilg from './components/index'
import methodlist from '../../utils/methods'
export default {
    components:{
        Tables,
        Tabheader,
        Attshitdilg
    },
    data(){
        return{
            flag:false,
            value1:'',
            searchParams: {
                name: '',
                serial_number: '',
                start_time: '',
                end_time: ''
            },
             titlename:{
                // name:this.$t('devtable.czz'),
                placeholdername:'请点击查询',
				// pltb:this.$t('devtable.tb')
            },
            operation: {
                width:'200px',
                data:[
                    {
                        name: '同步',
                        type: 'primary'
                    },
                ]
            },
            
            tableData: [
                
            ],
            flags: {
                show: true, // 多选是否显示
                ishow: true // 序号是否显示
            },
            // 分页数据
            configs: {
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
           UserInfo:'',
		   loading:null,
		   errDataList:[],
		   errPopState:false
        }
    },
    computed:{
        tableLabel: function(){
            return[
                {
                    prop: 'name',
                    label: this.$t('devtable.llkmc'),
                    align: 'center',
					width:130
                },
                 {
                    prop: 'serial_number',
                    label: this.$t('devtable.sbh'),
                    align: 'center'
                },
                {
                    prop: 'card_no',
                    label: this.$t('devtable.llkxh'),
                    align: 'center',
					width:120
                },
                {
                    prop: 'start_date',
                    label: this.$t('devtable.qysj'),
                    align: 'center',
					width:100
                },
                {
                    prop: 'total_flow',
                    label: this.$t('devtable.zll'),
                    align: 'center',
					width:100
                },
                {
                    prop: 'residual_flow',
                    label: this.$t('devtable.syll'),
                    align: 'center',
					width:115
                },
     //            {
     //                prop: 'buy_time',
     //                label: this.$t('devtable.czsj'),
     //                align: 'center',
					// width:120
     //            },
     //            {
     //                prop: 'buy_money',
     //                label: this.$t('devtable.czje'),
     //                align: 'center',
					// width:140
     //            },
            ]
        }
    },
    mounted () {
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getFlowCardList(1);
    },
    methods:{
		closePop(){
			this.errPopState = false
		},
		// 分页
		handlpages(v){
			console.log(v);
			this.getFlowCardList(v)
		},
		handBatch(e){
			console.log(e);
			// this.loading = this.$loading({
			// 	lock: true,
			// 	text: "正在批量同步中,请勿关闭",
			// 	spinner: "el-icon-loading",
			// 	background: "rgba(0, 0, 0, 0.7)"
			// })
			// let data = {
			// 	method:'co.flow.card.more.syn',
			// 	agent_id:this.UserInfo.agent_id,
			// }
			// this.$serve(data).then(res=>{
			// 	if(res.data.code==0){
			// 		this.$message({
			// 			type:"success",
			// 			message:'同步成功'
			// 		})
			// 	}else{
			// 		this.$message({
			// 			type:"error",
			// 			message:res.data.code+'个设备同步失败'
			// 		})
			// 		this.errDataList = res.data.data.split(',')
			// 		this.errPopState = true
			// 		console.log(this.errDataList);
			// 	}
			// 	this.loading.close(); //关闭弹窗
			// })
		},
		handlerow(e){
			console.log(e.card_no);
			let data = {
				method:'co.flow.card.syn',
				agent_id:this.UserInfo.agent_id,
				id:e.id
			}
			this.$serve(data).then(res=>{
				if(res.data.code==0){
					this.$message({
						type:"success",
						message:'同步成功'
					})
				}else if(res.data.code==-3){
					this.$message({
						type:'warning',
						message:'流量卡不存在'
					})
				}else if(res.data.code==-5){
					this.$message({
						type:'error',
						message:'同步失败，请重试'
					})
				}
			})
		},
        onSubmit() {
            console.log('submit!');
            this.getFlowCardList(1);
        },
        handRegister(){
            //this.$refs.attshitdilg.dialogFormVisible=true
        },  
        handFromshow(){
            this.flag = !this.flag
        },
        getFlowCardList(pageIndex){
            const params = {
                method: methodlist.ee_flow_card_list,
                agent_id: this.UserInfo.agent_id,
                manager_id:  this.UserInfo.manager_id, 
                page_index: pageIndex, 
                page_size: this.configs.pagesize,                
                name: this.searchParams.name,
                serial_number: this.searchParams.serial_number,
                start_time: this.searchParams.start_time,
                end_time: this.searchParams.end_time
            }
            this.$serve(params).then(res => {
                console.log(res);
				res.data.data.forEach(item=>{
					let time = new Date(item.start_date*1000)
					item.start_date = time.getFullYear() +'-' + (time.getMonth()+1) +'-' + time.getDate()
					console.log(item.start_date);
				})
                this.tableData = res.data.data;
                this.configs.total = res.data.count;
                this.tableData.forEach(el=>{

                })
            });              
        }
    }
}
</script>
<style lang="less" scoped>
.attshiftcont{
    padding: 20px;
    background-color: #fff;
    .attheadfrom{
        padding: 20px;
        margin-top: 10px;
    }
}
.errPop {
	position: fixed;
	top: 20px;
	left: 500px;
	background-color: #CCCCCC;
	width: 500px;
	border-radius: 10px;
}

.close {
	border: 1px solid #000;
	width: 20px;
	height: 20px;
	text-align: center;
	line-height: 20px;
	margin-left: auto;
	margin-right: 10px;
	margin-top: 10px;
}

.popTitle {
	font-size: 24px;
	text-align: center;
	margin-bottom: 20px;
}
.popNav {
	display: flex;
	margin-bottom: 10px;
}

.navIndex {
	flex: 3;
	text-align: center;
}

.scroll {
	height: 220px;
	overflow-y: scroll;
}

.itemBox {
	display: flex;
	border-top: 1px solid #000;
	margin-bottom: 10px;
	padding-top: 10px;
}
</style>